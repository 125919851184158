<template>
  <div class="WxpushIndex page">
    <div class="top">
      <a-input
        style="width: 120px; margin-right: 20px"
        placeholder="手机号"
        v-model:value="params.phone"
      ></a-input>
      <a-input
        style="width: 120px; margin-right: 20px"
        placeholder="用户名"
        v-model:value="params.username"
      ></a-input>
      <a-select
        style="width: 120px; margin-right: 20px"
        placeholder="营销任务"
        v-model:value="params.uuid"
      >
        <a-select-option
          v-for="item in jobList"
          :key="item.job_id"
          :value="item.job_id"
          >{{ item.job_name }}</a-select-option
        >
        <!-- <a-select-option value="lucy">教育培训</a-select-option>
        <a-select-option value="Yiminghe">pos机</a-select-option> -->
      </a-select>
      <a-select
        style="width: 180px; margin-right: 20px"
        mode="multiple"
        placeholder="客户类型"
        v-model:value="params.result_type"
      >
        <a-select-option value="2">A</a-select-option>
        <a-select-option value="3">B</a-select-option>
        <a-select-option value="4">C</a-select-option>
        <a-select-option value="5">D</a-select-option>
        <a-select-option value="6">E</a-select-option>
        <a-select-option value="7">F</a-select-option>
      </a-select>
      <a-range-picker
        style="width: 240px; margin-right: 20px"
        v-model:value="params.create_time"
        valueFormat="YYYY-MM-DD"
      />
      <a-button type="primary" @click="search">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <a-table
      :dataSource="data"
      :columns="columns"
      :pagination="{
        current: page,
        total: total,
        pageSize: count,
        showSizeChanger: true,
        showTotal: (total) => `总共 ${total} 条`,
        pageSizeOptions: ['20', '50', '100'],
      }"
      @change="pageChange"
      rowKey="id"
    >
      <template #result_type="{ record }">
        {{ handleType(record.result_type) }}
      </template>
      <template #state="{ record }">
        {{ sendState(record.state) }}
      </template>
      <template #create_time="{ record }">
        {{ formatDate(record.create_time) }}
      </template>
      <template #uuid="{ record }">
        {{ shouName(record.uuid) }}
      </template>

      <template #operation="{ record }">
        <a-button
          @click="callDetail(record)"
          size="small"
          type="primary"
          style="margin-right: 4px"
          >通话详情</a-button
        >
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      title="通话记录"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="1000"
      :footer="null"
      class="model-three"
    >
      <CallLog :number="number" :records="records" @playVideo="playVideo" />
    </a-modal>
  </div>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { getListw, getTaskList, getRecord } from "../../service/weixinnote";
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import { formatDate, handleType, sendState } from "../../utils/function";
import CallLog from "../../components/CallLog.vue";
const columns = [
  {
    title: "用户名称",
    dataIndex: "username",
  },
  {
    title: "任务名称",
    dataIndex: "uuid",
    slots: {
      customRender: "uuid",
    },
  },
  {
    title: "号码",
    dataIndex: "phone",
  },
  {
    title: "客户类型",
    dataIndex: "result_type",
    slots: {
      customRender: "result_type",
    },
  },
  {
    title: "发送状态",
    dataIndex: "state",
    slots: {
      customRender: "state",
    },
  },
  {
    title: "日期",
    dataIndex: "create_time",
    slots: {
      customRender: "create_time",
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
    fixed: "right",
  },
];
export default {
  name: "WxpushIndex",
  components: {
    SearchOutlined,
    CallLog,
  },
  setup() {
    const state = reactive({
      page: 1,
      data: [],
      total: undefined,
      count: 20,
      jobList: [],
      number: {}, //通话详情
      records: [], //通话详情
      visible: false,
    });
    const params = reactive({
      // count: 20,
      phone: undefined,
      username: undefined,
      uuid: undefined,
      result_type: [],
      create_time: [],
    });
    onMounted(async () => {
      const res = await getTaskList({
        access_token: sessionStorage.getItem("token"),
      });
      // console.log(res);
      state.jobList = res.data.data.list;
      init();
    });
    const init = async () => {
      // console.log(params);
      const data = {
        page: state.page,
        count: state.count,
        phone: params.phone,
        username: params.username,
        result_type: params.result_type.join(","),
        create_time:
          params.create_time.length !== 0
            ? JSON.stringify(params.create_time)
            : "",
      };
      const res = await getListw({
        access_token: sessionStorage.getItem("token"),
        ...data,
      });
      // console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const callDetail = async (record) => {
      console.log(record);
      state.records = [];
      state.visible = true;
      const res = await getRecord({
        access_token: sessionStorage.getItem("token"),
        job_id: record.uuid,
        id: record.number_id,
      });
      console.log(res);
      state.number = res.data.data.number;
      // state.records = res.data.data.records;
      res.data.data.records.forEach((item) => {
        item.play = false;
        state.records.push(item);
        if (item.child) {
          item.child.forEach((item2) => {
            item2.play = false;
            state.records.push(item2);
          });
        }
      });
    };
    const pageChange = (e) => {
      // console.log(e);
      state.page = e.current;
      state.count = e.pageSize;
      init();
    };
    const search = () => {
      // console.log(params);
      init();
    };
    const playVideo = (index) => {
      state.records.forEach((item) => {
        item.play = false;
      });
      state.records[index].play = true;
    };
    const shouName = (id) => {
      // console.log(id);
      let obj = state.jobList.find((item) => item.job_id === id);
      // console.log(state.jobList)
      return obj.job_name;
    };
    return {
      columns,
      ...toRefs(state),
      params,
      callDetail,
      pageChange,
      formatDate,
      handleType,
      sendState,
      search,
      playVideo,
      shouName,
    };
  },
};
</script>

<style scoped>
.WxpushIndex {
  padding: 10px;
  overflow: auto;
}
.top {
  margin-bottom: 15px;
}
</style>